.content.options-testing h2
{
    text-align: center;
    margin: 2rem 0;
}

.projects-container.options-testing
{
    grid-template-columns: repeat(4, 1fr);
}

.projects-container.options-testing > div:not(:nth-child(n + 5))
{
    display: none;
}

.content .navigate-button 
{
    position: absolute;
    top: 125px;
    cursor: pointer;
}

.content .navigate-button .navigate-icon 
{
    width: 24px;
    height: 24px;
    border: 5px solid #4481eb;
    border-radius: 50%;
    padding: 1rem;
    transition: all 300ms ease-in;
}

.content .navigate-button:hover .navigate-icon 
{
    color: #fff;
    background: #4481eb;

}