.categories-content .title 
{
    text-align: center;
    font-size: 50px;

}

.categories-content .modules 
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1.5rem;
}

/* .categories-content .modules .module 
{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    aspect-ratio: calc(16 / 9);
    background-size: cover;
    border-radius: 20px;
    padding-bottom: 1rem;
    cursor: pointer;
    transition: all 350ms ease-in-out;
} */

.categories-content .modules .module
{
    position: relative;
}

.categories-content .modules .module .read-icon
{
    margin-left: auto;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: .5rem;
    transition: all 350ms ease-in-out;
}

.categories-content .modules .module .read-icon:hover 
{
    box-shadow: 0 0 15px rgb(68,131,235),
    0 0 15px rgb(11,189,254);
    color: rgb(68,131,235);
    background: #fff;
}

.categories-content .modules .low-tension:hover 
{
    box-shadow: 0 0 15px rgb(68,131,235),
                0 0 15px rgb(11,189,254);
}

.categories-content .modules:not(.high-tension-modules) .high-tension:hover 
{
    box-shadow: 0 0 15px rgb(59,215,102),
                0 0 15px rgb(3,255,28);
}

.categories-content .modules .module h2 
{
    color: #fff;
    margin-top: auto;
    text-align: center;
    font-size: 35px;
    align-self: flex-end;
}


.categories-content .modules .low-tension 
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url("../../imgs/low-tension.png");

}

.categories-content .modules .high-tension 
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url("../../imgs/high-tension.png");
    
}

.categories-content .module-context 
{
    border: 6px solid rgba(0, 0, 0, 1);
}

.module-context.low-tension
{
    background: url("../../imgs/low-tension.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.module-context.low-tension.functional 
{
    background: url("../../imgs/modules/functionare.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

}

.module-context.low-tension.debugging 
{
    background: url("../../imgs/modules/depanare.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}



.module-context.high-tension
{
    background: url("../../imgs/high-tension.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.module-context.high-tension.learning 
{
    background: url("../../imgs/modules/invatare.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}


.categories-content h2.module-title
{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    color: #fff;
    justify-content: center;
    background: linear-gradient(135deg, #000 35%, #63808a);
    width: 100%;
    /* align-self: flex-end; */
    /* margin-bottom: -1px; */
    /* border-radius: 10px; */
    /* margin-bottom: 20px; */
    margin: 0 auto;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    bottom: 0;
    font-size: 30px;
    
}


.categories-content .modules .low-tension .lock-icon,
.categories-content .modules .high-tension .lock-icon
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 24px;
    color: #fff;
}

.categories-content .block-modules
{
    justify-content: flex-start;
    min-height: 50vh;
}

.categories-content .block-modules .module-context 
{
    cursor: pointer;
    flex: 2.5;
    transition: all 350ms ease-in-out;
}

.categories-content .block-modules .module-context:hover
{
    transform: scale(1.05);
    border-color: royalblue;
}

.categories-content .modules
{
    gap: 2.75rem;
}

.categories-content .modules  span 
{
    font-size: 20px;
}

.categories-content .modules-container 
{
    width: 95%;

}

.start-button-container-2
{
    display: flex;
    justify-content: center;
    align-items: center;

}

.start-button-container-2 .start-button 
{
    width: 100%;
    max-width: max(150px, 27.5%);
    padding-block: .75rem;
    border-radius: 10px;
    background: linear-gradient(135deg, #000 35%, #63808a);
    cursor: pointer;

    transition: all 500ms ease;
}

.start-button-container-2 .start-button:hover 
{
    /* transform: scale(1.075); */
    border-radius: 100px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    /* box-shadow: 0px 0px 5px #000 inset,
                -5px 0px 10px #000 inset; */
}


.start-button-container-2 .start-button h2
{
    text-align: center;
    color: #fff;
    font-size: 35px;
}

.docs-container 
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    top: calc(50% + 5vh);
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 70%;
    z-index: -1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 2rem;
    transition: all 350ms ease-in-out;
}


.docs-container.active 
{
    z-index: 10;

    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    backdrop-filter: blur(8px);
    border-radius: 30px;
}

.docs-container:not(.active) textarea
{
    display: none;
}

.docs-container textarea 
{
    background: transparent;
    width: 75%;
    margin: auto;
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    resize: none;
    margin-top: 20px;
    line-height: 30px;
}

.docs-container:not(.active) .close-icon 
{
    display: none;
}

.docs-container .close-icon 
{
    width: 30px;
    height: 30px;
    padding: .5rem;
    margin: .5rem;
    color: #fff;
    box-shadow: 0 0 25px rgb(68,131,235),
    0 0 10px #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 650ms ease-in-out, width 350ms ease-in-out, height 350ms ease-in-out;
}

.docs-container .close-icon:hover 
{
    transform: rotateZ(180deg);
    width: 40px;
    height: 40px;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
}