.scenario-button
{
    position: fixed;
    top: 15px;
    right: 15px;
    
}

.scenario-button .scenario-icon 
{
    color: #fff;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: 1rem;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.scenario-button .scenario-icon:hover
{
    box-shadow: 0 0 10px #4481eb,
                0 0 10px #04befe;
}


.scenario-paper
{
    aspect-ratio: 2;
    /* width: 75%; */
    /* min-height: 50%; */
    /* margin: 0 auto; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 100000;
    padding: 3rem 1.5rem;
    border-radius: 32px;
    display: none;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
}

.scenario-paper.active 
{
    display: flex;
}

.scenario-paper img 
{
    margin: 2rem auto;
    border-radius: 20px;
    width: 75%;
}

.scenario-paper p 
{
    font-size: 20px;
    color: var(--text-color-2);
}

.scenario-paper .close-button .close-icon
{
    color: rgba(0, 0, 0, .4);
    background: crimson;
    position: absolute;
    width: 22px;
    height: 22px;
    padding: .5rem;
    border-radius: 50%;
    cursor: pointer;
    top: 10px;
    right: 10px;
    transition: all 350ms ease-in-out;

}

.scenario-paper .close-button .close-icon:hover
{
    width: 24px;
    height: 24px;
    /* box-shadow: 0 0 10px crimson; */
}

    
.start-session-button.high-tension 
{
    width: 30%;
    bottom: 20px;
}

/* .start-session-button.high-tension:hover 
{
    width: 60%;
} */