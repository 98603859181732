body.grid-body
{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    min-height: 100vh;
}


html.grid-body 
{
    /* padding: 5vh; */
}

body.grid-scroll
{
    justify-content: center;
}

.margin
{
    margin-left: 2rem;
}

.page-container 
{
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 0mm 5mm 0mm 1mm;
    border: 5mm solid #63808a;
    /* margin: 2rem auto; */
    position: relative;
    cursor: url('../../imgs/figma_cursor.png'), auto;
    margin: 10vh auto 35vh auto;
}

.page-container[format="A4"]
{
    min-width: 297mm;
    min-height: 210mm;
    height: 210mm;
    width: 297mm;
    max-width: 297mm;
    max-height: 210mm;
}

.page-container[format="A3"]
{
    min-width: 420mm;
    min-height: 297mm;
    height: 297mm;
    width: 420mm;
    max-width: 420mm;
    max-height: 297mm;
}

.page-container .name-sign-container
{
    position: fixed;
    z-index: 100000000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .35);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
}

.page-container .remove-container 
{
    position: absolute;
    top: -65px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background: linear-gradient(135deg, crimson, rgb(238, 127, 15));
    border-radius: 35px;
    padding: .5rem 1rem;
    cursor: pointer;
}

.page-container .remove-container h2 
{
    font-size: 16px;
    color: rgba(255, 255, 255, .9);
    transition: all 350ms ease-in-out;
}

.page-container .remove-icon 
{
  
    font-size: 24px;
    color: rgba(255, 255, 255, .9);
}

.page-container .remove-container:hover 
{
    box-shadow: 0 0 10px crimson,
                0 0 10px rgb(238, 127, 15);
}

.page-container .name-sign-container .name-sign
{
    position: relative;
    border-radius: 16px;
}

.page-container .name-sign-container .name-sign button 
{
    padding: .5rem;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    border-radius: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    outline: none;
    cursor: pointer;
    margin-top: -.5rem;
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign button.min-button
{
    width: min-content;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign button.min-button p
{
    display: none;
    white-space: nowrap;
    font-size: 16px;
}

.page-container .name-sign-container .name-sign button:hover 
{
    transform: scale(1.1);
}

.page-container .name-sign-container .name-sign button .add-icon 
{
    color: #fff;
    width: 24px;
    height: 24px;
}

/* .page-container .name-sign-container .name-sign button.add-button
{
    position: sticky;
    bottom: 30px;
    z-index: 10000;
} */

.page-container .name-sign-container .name-sign button p 
{
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    font-family: "Poppins";
}

.page-container .name-sign-container .name-sign button p.hover-text
{
    transform: scale(0);
    /* width: 0; */
    /* transform-origin: left; */
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign button p.hover-text
{
    
}

.page-container .name-sign-container .name-sign button.min-button:hover
{
    width: fit-content;
}


.page-container .name-sign-container .name-sign button:hover p.hover-text
{
    display: flex;
    width: 100%;
    transform: scale(1);
}

.page-container .name-sign-container .name-sign .text-container 
{
    background: linear-gradient(135deg, rgb(225,225,237), rgba(170, 170, 170, .35));
    /* background-position: fixed; */
    background-attachment: fixed;


    /* background-image: linear-gradient(135deg, rgb(216,217,216) 70%, rgb(127,127,127)); */
    /* background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center; */
    padding: 0 .75rem;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    position: relative;
    margin-top: 1rem;
}

.page-container .name-sign-container .name-sign .text-container .input 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.page-container .name-sign-container .name-sign .text-container .input .input-text-wrapper
{
    display: flex;
    align-items: center;
    gap: .25rem;
}

.page-container .name-sign-container .name-sign .text-container .input h2
{
    font-size: 16px;
    flex: 1;
    font-weight: 500;
    /* white-space: nowrap; */
}

.page-container .name-sign-container .name-sign .text-container .input .input-text-wrapper p 
{
    text-align: center;
    color:rgb(89, 123, 226);
}

.page-container .name-sign-container .name-sign .text-container .input input 
{
    flex: 1;   
    /* min-width: 50px; */
    width: min-content;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, .55), rgba(255, 255, 255, .6));
    color: rgba(0, 0, 0, .90);
    font-weight: 500;
    outline: none;
    margin-right: .5rem;
    border: none;
    font-size: 20px;
    border-radius: 8px;

    font-family: "Poppins";
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign .text-container .input input:focus
{
    border-radius: 24px;

    border: 2.5px solid #4481eb;
}

.page-container .name-sign-container .name-sign .text-container .input div 
{
    flex: 1;
}

.page-container .name-sign-container .name-sign .text-container h2 
{
    font-weight: 600;
    font-size: 22px;
    margin-left: .5rem;
}

.page-container .name-sign-container .name-sign 
{
    width: 100%;
    /* max-width: 32.5vw; */
    width: 525px;
    background: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    gap: 1.5rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 85vh;
    overflow-y: auto;
}

.page-container .name-sign-container .name-sign .name-sign-relative 
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
}

.page-container .name-sign-container .name-sign .name-sign-relative select 
{
    border-radius: 6px;
    padding: 12px;
    flex: 1;   
    /* min-width: 50px; */
    width: min-content;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, .55), rgba(255, 255, 255, .6));
    color: rgba(0, 0, 0, .90);
    font-weight: 500;
    outline: none;
    margin-right: .5rem;
    border: none;
    font-size: 20px;
    border-radius: 8px;

    font-family: "Poppins";
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign .name-sign-relative .close-icon 
{
    color: crimson;
    position: absolute;
    top: -15px;
    right: -25px;
    width: 28px;
    height: 28px;
    z-index: 10000000;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign .name-sign-relative .delete-icon 
{
    color: crimson;
    position: absolute;
    top: -15px;
    left: -25px;
    width: 28px;
    height: 28px;
    z-index: 10000000;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}



.page-container .name-sign-container .name-sign .close-icon:hover,
.page-container .name-sign-container .name-sign .delete-icon:hover
{
    background: crimson;
    border-radius: 50%;
    color: #fff;
    padding: .3rem;
}

.page-container .name-sign-container .name-sign .text-container .close-icon 
{
    top: 8px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.page-container .name-sign-container .name-sign .input .options 
{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-top: 1rem;
    max-height: 200px;
    overflow-y: auto;
}

.page-container .name-sign-container .name-sign .input
{
    margin-top: 1rem;
    margin-bottom: 0rem;
    padding-bottom: 1rem;
}

.page-container .name-sign-container .name-sign .input:not(.last-input)
{
    border-bottom: 1px solid #000;
}

.page-container .name-sign-container .name-sign .input input 
{
    width: 100%;
    border: 2px solid rgba(125, 125, 125, .35);
    /* border-radius: 50px; */
    padding: .5rem;
    color:  #000;
    font-size: 16px;
}

.page-container .name-sign-container .name-sign .input input::placeholder
{
    
}

.page-container .name-sign-container .text-content 
{
    display: flex;
    justify-content: center;
    /* gap: 1rem; */
    align-items: center;
    gap: .35rem;
 
}

.page-container .name-sign-container .text-content .text-symbol 
{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    
}

.page-container .name-sign-container .text-content .text-symbol .text-symbol-icon 
{
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    padding: .6rem;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    color: #fff;
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .text-content .text-symbol .text-symbol-icon:hover
{
    box-shadow: 0 0 5px #000 inset, 
                0 0 5px #fff;
    width: 20px;
    height: 20px;

}

/* .page-container .name-sign-container .text-content .text-symbol:nth-child(2)
{
    flex-direction: row-reverse;
} */


.page-container .name-sign-container .text-content .text-content-square-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.page-container .name-sign-container .text-content .text-content-square 
{
    position: relative;
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    min-width: 15.5mm;
    max-width: 15.5mm;
    /* margin: 1rem; */
    overflow: hidden;
    /* margin-left: 30px; */
}

.page-container .name-sign-container .text-content .text-content-square h2 
{
    text-align: center;
    user-select: none;
    white-space: nowrap;
}

.page-container .name-sign-container .text-content .text-content-square img
{
    max-width: 100%;
    user-select: none;
    position: absolute;
}


.page-container .name-sign-container .name-sign .input .options .option 
{
    width: 100%;
 
    background: rgba(125, 125, 125, .35);
    padding: .5rem;
    border-radius: 50px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.page-container .name-sign-container .name-sign .input .options .option.active
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    color: #fff;

}


.page-container .name-sign-container:not(.active)
{
    display: none;
}

.page-container.non-grid 
{
    border-width: 0mm;
}

.page-container .numbers-container 
{
    display: flex;
 
    width: 100%;
    height: 10mm;
}

.page-container .numbers-container > p
{

    flex: 1;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-container .numbers-container .empty 
{
    flex: initial;
    width: 7mm;
}

.page-container .grid-total-container 
{
    display: flex;
    height: 195mm;

}

.page-container .grid-total-container .letters 
{
    width: 7mm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 0.5px solid grey;
    border-right: none;
    
}

.page-container .grid-total-container .letters p 
{
    flex: 1;
    border: 0.5px solid grey;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.page-container .grid-total-container .grid-container 
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 0.5px solid grey;
    border-right-width: .75px;
}

.page-container .grid-total-container .grid-container .row
{
    display: flex;
    flex: 1;
    width: 100%;
    height: 10mm;

}

.page-container .grid-total-container .grid-container .row .cell 
{
    flex: 1;
    border: .5px solid #8080802e;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    position: relative;
}

body.show-current .page-container .grid-total-container .grid-container .row .cell.has-current 
{
}

body .page-container .grid-total-container .grid-container .row .cell img.has-current.consumer 
{
    transition-delay: 100ms;
    filter: invert(54%) sepia(98%) saturate(528%) hue-rotate(5deg) brightness(99%) contrast(104%);
}


body.show-current .page-container .grid-total-container .grid-container .row .cell img.high-tension
{
    filter: none;
    filter: invert(59%) sepia(28%) saturate(954%) hue-rotate(84deg) brightness(108%) contrast(100%);
}

body.show-current .page-container .grid-total-container .grid-container .row .cell img.has-current.L1
{
    filter: invert(18%) sepia(78%) saturate(4151%) hue-rotate(348deg) brightness(93%) contrast(98%);
}

body.show-current .page-container .grid-total-container .grid-container .row .cell img.has-current.L2
{
    filter: invert(61%) sepia(68%) saturate(1289%) hue-rotate(340deg) brightness(111%) contrast(94%);

}

body.show-current .page-container .grid-total-container .grid-container .row .cell img.has-current.L3
{
    filter: invert(51%) sepia(95%) saturate(3131%) hue-rotate(168deg) brightness(97%) contrast(101%);
}

body.show-current .page-container .grid-total-container .grid-container .row .cell img.has-current.N
{
    filter: invert(53%) sepia(43%) saturate(617%) hue-rotate(93deg) brightness(95%) contrast(93%);
}

body.show-current .page-container .grid-total-container .grid-container .row .cell img.has-current.PE
{
    filter: invert(81%) sepia(75%) saturate(1168%) hue-rotate(357deg) brightness(85%) contrast(88%);

}



.current-visibility-container 
{
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    backdrop-filter: blur(4px);
    padding: .5rem 1.35rem;
    gap: 0rem;
    z-index: 100000;
    border-radius: 50px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.current-visibility-container:not(.dynamic-status) 
{
    padding: .5rem 2rem;
}

.current-visibility-container.dynamic-status 
{
    top: 100px;
}

.current-visibility-container.dynamic-status p 
{
    font-size: 16px;
    color: #fff;
}

.current-visibility-container.dynamic-status svg 
{
    color: #4481eb;
    margin-right: 10px;
}

.current-visibility-container .shortcut 
{
    color: #fff;
  
    border-radius: 8px;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 350ms ease-in-out;
}

.current-visibility-container .shortcut p 
{
    transform-origin: left;
    opacity: 0;
    font-size: 0;
    transition: all 350ms ease-in-out;
    /* transition-delay: 350ms; */
}


.current-visibility-container .shortcut svg 
{
    width: 24px;
    height: 24px;

}

.current-visibility-container .shortcut:hover 
{
    /* background: #fff; */
    /* color: #000; */
    /* border: 2px solid #fff; */
    border-radius: 24px;
    box-shadow: -2.5px -2.5px 7px #fff inset,
                -5px 0px 5px #000;

}

.current-visibility-container .shortcut.active:hover 
{
    gap: .5rem;
}


.current-visibility-container .shortcut.active:hover p 
{
    font-size: 18px;
    opacity: 1;
    display: block;
}

.current-visibility-container:hover 
{
    box-shadow: 0 0 10px royalblue, 
                5px 5px 10px #4481eb;
    padding: .5rem 2.5rem;
}

.current-visibility-container.dynamic-status:hover
{
    box-shadow: 0 0 10px #fff, 
    5px 5px 10px #fff;
}

.current-visibility-container .current-icon 
{
    font-size: 24px;
    color: royalblue;
}

.current-visibility-container.is-current .current-icon 
{
    
}

bility-container .current-line 
{
    position: absolute;
    background: #fff;
    height: 3px;
    border-radius: 50px;
    position: absolute;
    left: .75rem;
    top: 50%;
    width: 35px;

    transform: translateY(-50%) rotateZ(135deg) scaleX(0);
    /* transform-origin: left; */
    transition: all 350ms ease-in-out;
}

.current-visibility-container:not(.is-current) .current-line 
{
    transform: translateY(-50%) rotateZ(135deg) scaleX(1);
}

.current-visibility-container h2 
{
    color: #fff;
}


.page-container .grid-total-container .grid-container .row .cell .dot 
{
    position: absolute;
    /* opacity: 0; */
    background: #000;
    border-radius: 50%;
    z-index: 100;
}

/* .page-container .grid-total-container .grid-container .row .cell .dot:hover .shortcircuit-line
{
    transform: none;
} */

.page-container .grid-total-container .grid-container .row .cell .dot img 
{
    opacity: 0;
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: -450%;
    left: -100%;
    z-index: 10000000;
    transform: rotateZ(135deg);
    /* transform: translateX(-50%); */
    transition: all 350ms ease-in-out;
}

.page-container .grid-total-container .grid-container .row .cell .dot.activated:not(.sync-activated) img:not(.screw-driver)
{
    pointer-events: all;
    opacity: 1;
    user-select: none;
    cursor: pointer;
}

.page-container .grid-total-container .grid-container .row .cell .dot.activated:not(.sync-activated) .screw-driver-container.true-show img.screw-driver
{
    pointer-events: all;
    opacity: 1;
    user-select: none;
    cursor: pointer;
}

.page-container .grid-total-container .grid-container .row .cell .dot.defect img 
{
    top: -650%;
    left: -200%;

}


.page-container .grid-total-container .grid-container .row .cell .dot .screw-driver-option 
{
    opacity: 0;
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: -500%;
    left: 150%;
    
    transition: all 350ms ease-in-out;
}

.page-container .grid-total-container .grid-container .row .cell .dot.defect .screw-driver-option 
{
    top: -700%;
    
}

.page-container .grid-total-container .grid-container .row .cell .dot.activated:not(.sync-activated) .screw-driver-option.show 
{
    opacity: 1;
    user-select: initial;
    pointer-events: all;
    display: flex;
    gap: .5rem;
    flex-direction: column;
}

.page-container .grid-total-container .grid-container .row .cell .dot .screw-driver-option.show .option
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    border-radius: 20px;
    padding-inline: 1rem;
    padding-block: .25rem;
    color: #fff;
    position: relative;
    cursor: pointer;

}

.page-container .grid-total-container .grid-container .row .cell .dot.activated:not(.sync-activated) .screw-driver-container.true-show .screw-driver-option.show .option p 
{
    font-size: 20px;
    color: rgba(255, 255, 255, .85);
}


.page-container .grid-total-container .grid-container .row .cell .dot.activated:not(.sync-activated) img:hover 
{

}

.page-container .grid-total-container .grid-container .row .cell.horizontal .dot 
{
    top: 45%;
}


.page-container .grid-total-container .grid-container .row .cell.horizontal .dot p 
{
    bottom: -30px;
}

.page-container .grid-total-container .grid-container .row .cell .dot p 
{
    opacity: 0;
    /* opacity: 1; */
    user-select: none;
    pointer-events: none;
}

.page-container .grid-total-container .grid-container .row .cell:hover .dot p 
{
    position: relative;
    pointer-events: all;

}

.page-container .grid-total-container .grid-container .row .cell.vertical .dot p 
{
    right: 50px;
    top: -5px;
    /* font-size: 14px; */

}


.page-container .grid-total-container .grid-container .row .cell .dot.top p 
{
    bottom: initial;
    top: -30px;
    left: -5px;
}

/* .page-container .grid-total-container .grid-container .row .cell.vertical-position .dot.top p 
{
    top: -5px;
} */


.page-container .grid-total-container .grid-container .row .cell .dot:hover  
{
    transform: scale(1.85);
}

.page-container .grid-total-container .grid-container .row .cell .dot .screw-line 
{
    width: 100%;
    pointer-events: none;
    height: 3px;
    background: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 350ms ease-in-out;
}



.page-container .grid-total-container .grid-container .row .cell .dot.activated img:hover
{
    transform: rotateZ(135deg) scale(1.25);
    animation: rotateScrew 500ms linear;
}

.page-container .grid-total-container .grid-container .row .cell .dot.activated
{
    transform: scale(1.85);
}  

/* .page-container .grid-total-container .grid-container .row .cell:hover .dot
{
    opacity: 1;
} */


.page-container .grid-total-container .grid-container .row .cell:hover .dot p
{
    opacity: 1;
}

.page-container .grid-total-container .grid-container .row .cell .dot.activated 
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
}

.page-container .grid-total-container .grid-container .row .cell .dot.defect 
{
    background: crimson;
    border: 2px solid #000;
}

.page-container .grid-total-container .grid-container .row .cell .dot.sync-activated 
{
    background: orange;
}

.page-container .grid-total-container .grid-container .row .cell.horizontal .dot.max-left 
{
    left: -5px;
}

/* .page-container .grid-total-container .grid-container .row .cell.vertical .dot.max-left 
{
    top: -15px;
} */


.page-container .grid-total-container .grid-container .row .cell.horizontal .dot.left 
{
    left: 25%;
    /* transform: translateX(-25%); */
}

/* .page-container .grid-total-container .grid-container .row .cell.vertical .dot.left 
{
    top: 5px;
} */


.context-menu 
{
    position: absolute;
    width: 200px;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    backdrop-filter: blur(4px);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10;
    z-index: 100000000000;
    border: 1px solid rgba(0, 0, 0, .85);
    box-shadow: 0 0 5px #000;
}

.context-menu:hover 
{
    
}

.context-menu .context-menu-icon 
{
    cursor: pointer;
    margin: 1rem;
    display: flex;
    gap: 1rem;
    padding: .5rem;
    align-items: center;
    transition: all 400ms ease-in-out;
}

.context-menu .context-menu-icon p 
{
    color: rgb(225, 225, 255);
    white-space: nowrap;
}


/* .context-menu .context-menu-icon.danger p 
{
    color: crimson;
} */

.context-menu .context-menu-icon .right-menu-icon
{
    color: rgb(225, 225, 255);
    font-size: 18px;
}

.context-menu .context-menu-icon .right-menu-icon
{
    position: relative;
    z-index: 1000;
}

.context-menu .context-menu-icon.danger .right-menu-icon
{
    color: crimson;
}


.context-menu .context-menu-icon:hover 
{
    border-radius: 60px;
    padding: .5rem 1rem;
    box-shadow: 0 0 10px #4481eb;
    /* background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8)); */
    /* background: linear-gradient(135deg, rgba(255, 255, 255, .85) 35%, rgba(99, 128, 138, .8)); */

    /* color: #000; */
    box-shadow: -2.5px -2.5px 5px #fff inset,
    -5px 0px 5px #000;
    /* color: #fff; */
}

.page-container .grid-total-container .grid-container .row .cell .dot.top 
{
    /* top: 0px; */

}

.context-menu .context-menu-icon:hover .right-menu-icon
{
    /* color: #000; */
    
}

.context-menu .context-menu-icon.two-icons
{
    cursor: initial;
}

.context-menu .context-menu-icon.two-icons .right-menu-icon:hover
{
    cursor: pointer;
    background: rgba(255, 255, 255, .8);
    color:rgba(0, 0, 0, .85);
    padding: .4rem;
    font-size: 20px;
    border-radius: 50%;
}

.context-menu .context-menu-icon:hover p 
{
    /* color: #000; */
}

.context-menu .context-menu-icon .right-menu-icon
{
    transition: all 350ms ease-in-out;
}

.context-menu .context-menu-icon .right-menu-icon.danger:hover
{
    color: red;
}

.page-container .grid-total-container .grid-container .row .cell.horizontal .max-right 
{
    right: -5px;
}


/* .page-container .grid-total-container .grid-container .row .cell.vertical-position .max-right
{
    bottom: 0px;
} */

/* .page-container .grid-total-container .grid-container .row .cell.horizontal .dot.right 
{
    left: 75%;
    transform: translateX(-75%);
} */

/* .page-container .grid-total-container .grid-container .row .cell.vertical-position .dot.right 
{
    bottom: 20px;
} */






.page-container .grid-total-container .grid-container .row .cell.selected
{
    background: rgba(95, 163, 236, 0.456);
    /* background: linear-gradient(135deg, #4481ebb7 0%, rgba(95, 163, 236, 0.544) 100%); */

    outline: none;
    border: 2px solid rgba(0, 0, 0, .85);
}

.page-container .grid-total-container .grid-container .row .cell.copy
{
    border-style: dotted;
}

.page-container .grid-total-container .grid-container .row .cell img 
{
    width: 100%;
    user-select: none;
    position: absolute;
    transition: filter 350ms ease-in-out;
}

.page-container .grid-total-container .grid-container .row .cell h2 
{
    position: absolute;
    white-space: nowrap;
    font-size: 16px;
    z-index: 900;
    outline: none;
    
    /* transition: all 350ms ease-in-out; */
}

.page-container .grid-total-container .grid-container .row .cell h2.selected 
{
    background: rgba(0, 0, 0, .2);
    /* aspect-ratio: 1; */
    backdrop-filter: blur(4px);
}

.page-container .grid-total-container .grid-container .row .cell h2.top-left 
{
    top: 5px;
    left: 5px;
}

.page-container .grid-total-container .grid-container .row .cell h2.top-right 
{
    top: 5px;
    right: 5px;
}

.page-container .grid-total-container .grid-container .row .cell h2.top-center
{
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
} 



.page-container .grid-total-container .grid-container .row .cell h2.bottom-right 
{
    bottom: 5px;
    right: 5px;
}

.page-container .grid-total-container .grid-container .row .cell h2.bottom-left 
{
    bottom: -5px;
    left: 0px;
}

.page-container .grid-total-container .grid-container .row .cell h2.bottom-center
{
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.page-container .grid-total-container .grid-container .row .cell h2.center-left
{
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.page-container .grid-total-container .grid-container .row .cell h2.center-center 
{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.page-container .grid-total-container .grid-container .row .cell h2.center-right 
{
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
}


/* .page-container .grid-total-container .grid-container .row .cell img.on 
{
    filter: invert(54%) sepia(98%) saturate(528%) hue-rotate(5deg) brightness(99%) contrast(104%);
} */

.page-container .grid-total-container .row .cell img.turned-on 
{
    filter: invert(54%) sepia(98%) saturate(528%) hue-rotate(5deg) brightness(99%) contrast(104%);
}

.page-container .grid-total-container .grid-container .row .cell img.spin
{
    animation: spinEngine 3s linear infinite;
}

.page-container .grid-total-container .grid-container .row .cell img.semi-spin 
{
    animation: semiSpinEngine 1.5s linear infinite;
}

.page-container .grid-total-container .grid-container .row .cell img.reverse-spin
{
    animation: reverseSpinEngine 3s linear infinite;
}

.page-container .grid-total-container .grid-container .row .cell img.reverse-semi-spin 
{
    animation: reverseSemiSpinEngine 1.5s linear infinite;
}

.page-container .print-button 
{
    top: 17.5px;
    right: 5px;
    position: fixed;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.page-container .print-button .print-icon
{
    color: rgb(89, 123, 226);
    font-size: 22px;
    padding: .5rem;
    transition: all 350ms ease-in-out;
}

.page-container .measurement-device
{
    position: fixed;
    right: 15px;
    bottom: 10px;
    width: 100%;
    max-width: 300px;
    z-index: 10000;
}

 .guide-button
{
    position: fixed;
    left: 120px;
    bottom: 20px;
    /* width: 100%; */
    z-index: 10000000000;
    border-radius: 50%;
    padding: 1rem 1.15rem;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

 .guide-button:hover 
{
    transform: scale(1.2);
}

.guide-button .guide-icon 
{
    color: #fff;
    width: 32px;
    height: 32px;
}

.steps-guide-container
{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #000;
    gap: 1.5rem;
    padding-top: 5rem;
    padding-inline: 1rem;
}

.steps-guide-container h2 
{
    color: #fff;
    text-align: center;
}

.steps-guide-container .close-icon-container
{
    z-index: 1000000000;
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
    transition: all 350ms ease-in-out;
}

.steps-guide-container .close-icon-container .close-icon 
{
    width: 24px;
    height: 24px;
    color: #000;
    transition: all 350ms ease-in-out;
}

.steps-guide-container .close-icon-container:hover 
{
    background: #000;
    border: 2px solid #fff;
}

.steps-guide-container .close-icon-container:hover .close-icon
{   
    color: #fff;
}

.steps-guide-container .step 
{
    width: 100%;
    padding: .5rem 2rem;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    cursor: pointer;
    margin: 0 auto;
    border-radius: 8px;
    transition: all 350ms ease-in-out;
}

.steps-guide-container .step:hover,
.steps-guide-container .step.active
{
    transform: scale(1.1);
}

.steps-guide-container .step p 
{
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    transition: all 350ms ease-in-out;
}

.steps-guide-container .step:hover p,
.steps-guide-container .step.active p
{
    font-weight: bold;
}




.page-container .measurement-device .screen 
{
    width: 100%;
    height: 80px;
    background: #A2B296;
    border-radius: 8px 8px 0 0;
    border: 6px solid #535353;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    cursor: pointer;
}

.page-container .measurement-device .screen h2 
{
    font-size: 60px;
    letter-spacing: 5px;
}

.page-container .measurement-device .device
{
    position: relative;
}

.page-container .measurement-device .device img
{
    width: 100%;
    min-width: 300px;
}

.page-container .measurement-device .device .arrow-element 
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 700ms ease-in-out;
    transition-timing-function: cubic-bezier(0.1, 0.7, 0.8, 0.1);

}

.page-container .measurement-device .device .arrow-element .triangle 
{
    width: 0;
    height: 0;
    border-left: 17.5px solid transparent;
    border-right: 17.5px solid transparent;
    border-top: 30px solid #DB6262;
    /* border-radius: 10px; */
}

.page-container .measurement-device .device .dot 
{
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.page-container .measurement-device .device .dot:hover 
{
    width: 25px;
    height: 25px;
}

.page-container .measurement-device .device .dot-off
{
    top: 21.5%;
    left: 50%;
    transform: translateY(-21.5%) translateX(-50%);
}

.page-container .measurement-device .device .dot-acv-200
{
    top: 40.5%;
    right: 12%;
    transform: translateY(-40.5%) translateX(-12%);
}

.page-container .measurement-device .device .dot-acv-750 
{
    
    top: 66.5%;
    right: 13%;
    transform: translateY(-66.5%) translateX(-13%);
}

.page-container .measurement-device .device .dot-buzzer 
{
    top: 79%;
    left: 50%;
    transform: translateY(-79%) translateX(-50%);
}

.page-container .measurement-device .device .dot-continuity-10k
{
    top: 54.5%;
    left: 12%;
    transform: translateY(-54.5%) translateX(-12%);
}

.page-container .measurement-device .device .dot.active
{
    background: #DB6262;
}

.page-container .measurement-device .device .arrow-element .arrow 
{
    width: 32px;
    height: 120px;
    border: 2px solid #fff;
    border-bottom: none;
    border-top-left-radius: 6.5px;
    border-top-right-radius: 6.5px;
}


.page-container .measurement-device .screen h2 
{
    font-family: "Digital";
    user-select: none;
}

.page-container .print-button
{
    
}

.page-container .print-button:hover .print-icon
{
    background: rgb(89, 123, 226);
    border-radius: 50%;
 
    color: #fff;

}



.project-name 
{
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    backdrop-filter: blur(4px);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    z-index: 1000000000000;
}

.project-name h2 
{
    color: #fff;
    border: none;
    outline: none;
    text-align: center;
    border-top: 1px solid #fff;
    
}

.page-container .page-number 
{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 10px;
    right: 65px;
    
}

.page-container .page-number h2 
{
    font-size: 30px;
    border: 2px solid #000;
    padding: 0 .3rem;
    font-family: "Source Sans Pro", "Poppins", sans-serif;
}

.page-container .add-signs-container .elements
{
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    padding: 12px 50px 4px 50px;
    overflow-x: auto;
    
}

.page-container .add-signs-container .elements.category
{
    grid-gap: 2rem;
}

.page-container .collection-container
{
    position: absolute;
    bottom: -5px;
    right: 0px;
    /* right: 0; */
    cursor: pointer;
   
}

.page-container .collection-container .collection-icon
 {
    width: 16px;
    height: 16px;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    color: #fff;
    padding: .5rem;
    border-radius: 50%;
 }

.page-container .flip-container
{
    opacity: 0;
    user-select: none;
    cursor: initial;
    transition: all 350ms ease-in-out;
}

.page-container .flip-container.collection 
{
    display: none;
    /* pointer-events: none; */
}


.page-container .element:hover .flip-container
{
    opacity: 1;
    user-select: all;
    cursor: pointer;
}





.page-container .flip-container 
{
    position: absolute;
    z-index: 1000;
}

.page-container .flip-container .flip-icon 
{
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    color: #fff;
    padding: .5rem;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: all 350ms ease;
}

.page-container .element:hover .flip-container:hover .flip-icon
{
    width: 20px;
    height: 20px;
}

.page-container .flip-container.left
{
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
}

.page-container .flip-container.right
{
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
}



.add-signs-container {
    position: fixed;
    bottom: 35px;
    z-index: 10000000;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    left: 0;
    width: 100%;

}

.add-signs-container .elements-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 32px 0 32px; */
}

.add-signs-container .elements-container .scroll-element
{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 100%;
    position: absolute;
    background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    backdrop-filter: blur(2px);
    border-radius: 4px;
    top: 0;
    cursor: pointer;
    z-index: 1001;
    transition: all 350ms ease-in-out;
}

.add-signs-container .elements-container .scroll-element:hover 
{
    box-shadow: 0 0 10px #fff;
    min-width: 45px;
}

.add-signs-container .elements-container .scroll-element:not(.active)
{
    display: none;
}

.add-signs-container .elements-container .scroll-element .scroll-icon 
{
    color: #fff;
    font-size: 20px;
    transition: all 350ms ease-in;
}

.add-signs-container .elements-container .scroll-element:hover .scroll-icon 
{
    font-size: 24px;
}

.add-signs-container .elements-container .scroll-element:first-child 
{
    left: 0;
}

.add-signs-container .elements-container .scroll-element:last-child
{
    right: 0;
} 


.add-signs-container h2.title 
{
    color: #fff;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    margin-bottom: -16px;
}

.add-signs-container .back-button 
{
    position: absolute;
    left: 47.5px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: #000;
    padding: 0rem 1.5rem;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000;
    transition: all 350ms ease-in-out;
}

.add-signs-container .back-button .back-icon 
{
    font-size: 20px;
    color: rgb(89, 123, 226);
    transition: all 350ms ease-in-out;
}

.add-signs-container .back-button h2 
{
    color: #fff;
    font-size: 22px;
    transition: all 350ms ease-in-out;
}

.add-signs-container .back-button:hover 
{
    background: #fff;
}

.add-signs-container .back-button:hover h2 
{
    color: #000;
}

.add-signs-container .back-button:hover .back-icon 
{
    color: #04befe;
}


.page-container .add-signs-container .element 
{
    flex: 1;
    background: #fff;
    /* height: 100%; */
    /* aspect-ratio: 1; */
    min-width: 135px;
    max-width: 175px;
    /* width: 15%; */
    /* aspect-ratio: 2; */
    margin-top:.25rem;
    margin-bottom: .5rem;
    /* position: relative; */
}   

.page-container .add-signs-container .element-inside-container 
{
    position: relative;
    width: 100%;
    height: 100%;
}

.add-signs-container .element .name-element 
{
    opacity: 0;
    user-select: none;
    position: absolute;
    z-index: 10000;
    top: 5px;
    padding: .2rem 1rem;
    background: #000;
    border-radius: 50px;
    cursor: initial;
    pointer-events: none;
    transition: all 350ms ease-in-out;
    transition-delay: 0s;
}

.add-signs-container .element .name-element p 
{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
}

.add-signs-container .element.hovered .name-element
{
  
}

.add-signs-container .element:hover .name-element
{
    opacity: 1;
    cursor: pointer;
    z-index: 100000;
    user-select: initial;
   
    transition-delay: 1s; 
}

/* .page-container .add-signs-container .element:last-child 
{
    margin-left: auto;
} */

.page-container .add-signs-container .element 
{
    overflow: hidden;
    width: 90%;
    text-align: initial;
    box-shadow: inset 5px 5px 9px #989898,
            inset -5px -5px 9px #ffffff;
    transition: all 350ms ease-in-out;
    

}

.page-container .add-signs-container .element p 
{
    text-overflow: ellipsis;
    font-size: 18px;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;

}

.page-container .add-signs-container .element .name-element p
{
    text-overflow: initial;
    overflow: initial;
}

.page-container .add-signs-container .element.element-category > p 
{
    display: none;
}

.page-container .add-signs-container .element:hover 
{
    box-shadow: 0 0 10px #fff;
}

.page-container .add-signs-container .element .element-image 
{
}


.page-container .add-signs-container .element .element-image img 
{
   height: 5vh;
   padding: .2rem;

}

.page-counter-container 
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: 18px;
    color: #fff;
    margin-right: 1rem;

}

.page-counter-container p:nth-child(2)
{
    margin-right: 1rem;
}

.page-counter-container input 
{
    width: 100%;
    max-width: 50px;
    text-align: center;
    background: transparent;
    outline: 0;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.page-counter-container input:focus 
{
    outline: 2px solid rgba(255, 255, 255, .8);
    font-size: 17px;
    box-shadow: 0 0 10px #fff;
}

.page-counter-container input:hover 
{
    outline: 2px solid rgba(255, 255, 255, .8);
    font-size: 17px;
    box-shadow: 0 0 10px #fff;
}



/* Animations */

@keyframes spinEngine {
    0% {
        transform: rotateZ(0deg) scale(1.5);
    } 50% {
        transform: rotateZ(180deg) scale(1.5);
    } 100% {
        transform: rotateZ(360deg) scale(1.5);
    }
}

@keyframes semiSpinEngine {
    0% {
        transform: rotateZ(0deg) scale(1.5);
    } 50% {
        transform: rotateZ(90deg) scale(1.5);
    } 100% {
        transform: rotateZ(0deg) scale(1.5);
    }
}

@keyframes reverseSpinEngine {
    0% {
        transform: rotateZ(0deg) scale(1.5);
    } 50% {
        transform: rotateZ(-180deg) scale(1.5);
    } 100% {
        transform: rotateZ(-360deg) scale(1.5);
    }
}

@keyframes reverseSemiSpinEngine {
    0% {
        transform: rotateZ(0deg) scale(1.5);
    } 50% {
        transform: rotateZ(-90deg) scale(1.5);
    } 100% {
        transform: rotateZ(0deg) scale(1.5);
    }
}

@keyframes rotateScrew {
    0% {
        top: -550%;
    } 

    33% {
        top: -500%;

    } 

    66% {
        top: -600%;

    }

    100% {
        top: -450%;
    }
}
