/* transform: scale(0.2,0.3) skewY(-10deg); */
.pages-container 
{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 0 100px;
}

.pages-container .new-page
{
    min-width: 297mm;
    width: 297mm;
    max-width: 297mm;
    min-height: 210mm;
    height: 210mm;
    max-height: 210mm;
    background: rgba(125, 125, 125, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.pages-container .new-page .label 
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: .5rem 1rem;
    border-radius: 30px;
    transition: all 350ms ease-in-out;
}

.pages-container .new-page .label:hover 
{
    box-shadow: 0 0 8px #4481eb,
                0 0 8px #04befe;
}

.pages-container .new-page .label .add-icon 
{
    color: #fff;
    font-size: 20px;
}

.pages-container .new-page .label h2
{
    color: #fff;
    font-weight: 600;
}

.pages-container .page-data 
{
    min-width: 297mm;
    width: 297mm;
    max-width: 297mm;
    min-height: 210mm;
    height: 210mm;
    max-height: 210mm;
    background: rgba(125, 125, 125, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: relative;
}

.pages-container .page-data .tabel 
{
    display: flex;
    flex-direction: column;
    width: 75%;
    border: 4px solid #000;
    border-radius: 15px;
}

.pages-container .page-data .tabel .row 
{
    display: flex;
}

.pages-container .page-data .tabel .row:not(:first-child):not(:last-child)
{
    border: 2px solid #000;
    border-left: none;
    border-right: none;
}

.pages-container .page-data .tabel .row:first-child 
{
    border-bottom: 2px solid #000;
}

.pages-container .page-data .tabel .row:last-child 
{
    border-top: 2px solid #000;
}


.pages-container .page-data .tabel .row .label 
{
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.pages-container .page-data .tabel .row > .label:first-child 
{
    border-right: 3px solid #000;
    
}


.pages-container .page-data .tabel .row .label p
{
    text-align: center;
}

.pages-container .page-data .tabel .row > .label:last-child
{
    font-weight: bold;
}



.pages-container .page-data .tabel .row .label.two-sides p 
{
    flex: 1;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
}

.pages-container .page-data .tabel .row .label.two-sides > p:last-child
{
    
}

.pages-container .page-data .tabel .row .label.two-sides > p:first-child 
{
    border-right: 2px solid #000;
    margin-left: 0.5rem;
}


.pages-container .page-data .created-by 
{
    position: absolute;
    left: 20px;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: 100%;
}

.pages-container .page-data .created-by img 
{
    width: 50px;
    height: auto;
    margin-left: auto;
    margin-right: 35px;
}

.pages-container .page-data .created-by h2 
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

