.leaderboard 
{
    width: 90%;
    margin: 0 auto;
    background: rgba(125, 125, 125, .35);
    max-height: 75vh;
    padding: 1rem;
    border-radius: 10px;
}

.leaderboard .row,
.leaderboard .user
{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-block: 1rem;
    padding: 1rem;
    border-radius: 50px;
    transition: all 350ms ease-in-out;
}

.leaderboard .row
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);

}

.leaderboard .row > div p 
{
    color: #fff;
}

.leaderboard .row > div .column-icon 
{
    color: #fff;
    font-size: 18px;
}

.leaderboard .row > div,
.leaderboard .user > .column
{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}


.leaderboard .user:hover,
.leaderboard .user.active
{
    cursor: pointer;
    box-shadow: 0 0 15px #4481eb,
                0px 0px 15px #04befe;
}

.session-containers
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.join-session-container,
.finish-session-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 35%;
    gap: 1rem;
    border-radius: 25px;
    padding: .5rem 1rem;
    margin: 1rem 5rem;
    cursor: pointer;
    border: 2px solid #4481eb;
    transition: all 350ms ease-in-out;
}

.finish-session-container
{
    margin-left: 0;
}

.join-session-container h2,
.join-session-container .icon,
.finish-session-container h2,
.finish-session-container .icon
{
    transition: all 350ms ease-in-out;
}


.join-session-container:hover,
.finish-session-container:hover
{
    background: linear-gradient(135deg, #4481eb, #04befe);
    border-color: #fff;
}


.join-session-container:hover h2,
.join-session-container:hover .icon,
.finish-session-container:hover h2,
.finish-session-container:hover .icon
{
    color: #fff;
  
}

.join-session-container .icon,
.finish-session-container .icon
{
    font-size: 24px;
}

.leaderboard 
{
    
}