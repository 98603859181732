@font-face {
  font-family: "Digital";
  src: url("./fonts/digital-7/digital-7 (mono).ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


:root 
{
  --background: #080f23;
  --contrast-color: rgb(68,131,235);
  --contrast-color-highlight: #6f36e9;
  --contrast-color-2: #579AFF;
  --contrast-color-3: #FF447C;
  --background-color: #192846;
  --background-contrast: rgba(0, 0, 0, .6);
  --dark-color: #000;
  --light-color: #fff;
  --text-color: rgba(255, 255, 255, .8);
  --text-color-2: #fff;
  --text-color-3: rgba(255, 255, 255, .6);
  --text-color-4: rgba(255, 255, 255, .5);
  --selector-color: rgba(10, 110, 114, 0.5);
  --over-color: rgba(0, 0, 0, .2);
  --over-color-2: rgba(0, 0, 0, .6);
  --over-difference-color: rgba(255, 255, 255, .2);
  --over-difference-color-2: rgba(255, 255, 255, .075);
  --over-difference-color-3: linear-gradient(-135deg, rgba(125, 125, 255, 0.13), rgba(125, 125, 255, 0.4));
  --error-color: crimson;
  --success-color: #589858;
  --selected-color: rgba(95, 61, 128, 0.973);
  --warning-color: #ff9966;
  --home-text-color: rgba(0, 0, 0, 1);
  --home-contrast-color: rgb(68,131,235);
  --home-contrast-color-2: rgb(11,189,254);
  --home-text-color-2: #7F7F7F;
  --home-text-color-transparent: rgba(255, 255, 255, .8);
  --home-opposite-text-color-transparent: rgba(0, 0, 0, .8);
  --home-opposite-background-color: #000;
  --home-opposite-text-color: #fff;

  --home-background-container: rgb(245,245,245);
}

.App {
  text-align: center;
}

body[zoom]
{
  zoom: attr(zoom);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}