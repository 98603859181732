@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

*,
*::before,
*::after 
{
    box-sizing: border-box;
}

body 
{
    margin: 0rem 0 0 0;
    padding: 0;
    /* font-family: "Open Sans", sans-serif; */
    font-weight: 500;
}

.img-content 
{
    user-select: none;
}

.scheme-container 
{
    width: 100%;
    min-width: 1200px;
}

.scheme-container .consumer 
{
  
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    
}

.scheme-container .consumer .circle 
{
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    border-radius: 50%;
    background: transparent;
    z-index: 10;
    transition: all 350ms ease-in-out;
    position: relative;
}

.scheme-container .consumer .circle:last-child
{
  
    top: -20px;
   
}

.scheme-container .consumer .circle.colored 
{
    z-index: 9;
}





.scheme-container .consumer.inactive .circle.colored 
{
    background: #00FF19;
}

.scheme-container .consumer.active .circle.colored 
{
    background: crimson;
}

.scheme-container .consumer-4T 
{
    left: 161px;
    top: 171px;
}

.scheme-container .consumer-3T 
{
    left: 539px;
    top: 171px;
}

.scheme-container .consumer-2T
{
    left: 1330px;
    top: 161px;
}

.scheme-container .consumer-1T 
{
    left: 1683px;
    top: 164px;
}

.scheme-container .consumer-OE4
{
    left: 164px;
    top: 851px;
}

.scheme-container .consumer-LPGS2
{
    left: 297px;
    top: 851px;
}

.scheme-container .consumer-OE2 
{
    left: 421px;
    top: 851px;
}

.scheme-container .consumer-OE3
{
    left: 1493px;
    top: 851px;

}

.scheme-container .consumer-OE1 
{
    left: 1686.4px;
    top: 850px;
}

.scheme-container .intreruptor 
{
    width: 39px;
    height: 52px;
    position: absolute;
    z-index: 2;
    border: 3px solid #000;
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.scheme-container .line 
{
    transition: all 350ms ease-in-out;
    width: 100%;
    height: 4px;
}

.scheme-container .inactive .line
{
    background: #00FF19;
  
}

.scheme-container .active .line 
{
    background: crimson;
    transform: rotateZ(90deg);
}

.scheme-container .intreruptor.active .line 
{
    transform: scaleY(1.4) rotateZ(90deg);
}

.scheme-container .intreruptor-4T
{
    left: 164px;
    top: 299px;
}

.scheme-container .intreruptor-3T
{
    left: 542px;
    top: 299px;
}

.scheme-container .intreruptor-CLII
{
    left: 859px;
    top: 306px;
}

.scheme-container .intreruptor-2T
{
    left: 1335px;
    top: 289px;
}

.scheme-container .intreruptor-1T
{
    left: 1686px;
    top: 292px;
}

.scheme-container .intreruptor-OE4 
{
    left: 168px;
    top: 707px;
}

.scheme-container .intreruptor-LPGS2
{
    left: 302px;
    top: 707px;
}

.scheme-container .intreruptor-OE2 
{
    left: 425px;
    top: 707px;
}

.scheme-container .intreruptor-CTVB
{
    left: 580px;
    top: 706px;
}

.scheme-container .intreruptor-GeneratorI
{
    left: 671.5px;
    top: 706px;    
}

.scheme-container .intreruptor-CLI
{
    left: 862px;
    top: 705px;
}

.scheme-container .intreruptor-GeneratorII
{
    left: 1204.5px;
    top: 704px;
}

.scheme-container .intreruptor-CTVA
{
    left: 1302px;
    top: 704px;
}

.scheme-container .intreruptor-OE3 
{
    left: 1498px;
    top: 707px;
}

.scheme-container .intreruptor-OE1
{
    left: 1692.4px;
    top: 706px;
}

.scheme-container .separator 
{
    width: 30px;
    height: 30px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    

}

.scheme-container .separator.inactive .line
{
    background: #3AD766;
}


.scheme-container .separator-4T[number="1"]
{
    left: 136px;
    top: 435px;
}

.scheme-container .separator-4T[number="2"]
{
    left: 207px;
    top: 435px;
}

.scheme-container .separator-3T[number="1"]
{
    left: 514px;
    top: 435px;
}

.scheme-container .separator-3T[number="2"]
{
    left: 585px;
    top: 435px;
}

.scheme-container .separator-CMIB[number="4"]
{
    left: 750.99px;
    top: 414.77px;
}

.scheme-container .separator-CMIB[number="3"]
{
    left: 795px;
    top: 368px;
}

.scheme-container .separator-CLII[number="1"]
{
    left: 863px;
    top: 434px;
}

.scheme-container .separator-CLII[number="2"]
{
    left: 1002.5px;
    top: 431px;
}

.scheme-container .separator-CMIA[number="4"]
{
    left: 1129.5px;
    top: 412.77px;
}

.scheme-container .separator-CMIA[number="3"]
{
    left: 1081px;
    top: 373px;
}

.scheme-container .separator-2T[number="1"]
{
    left: 1307px;
    top: 425px;
}

.scheme-container .separator-2T[number="2"]
{
    left: 1378px;
    top: 425px;
}

.scheme-container .separator-1T[number="1"]
{
    left: 1658px;
    top: 428px;
}

.scheme-container .separator-1T[number="2"]
{
    left: 1729px;
    top: 428px;
}

.scheme-container .separator-OE4[number="1"]
{
    left: 133px; /* 31*/
    top: 593px; /* 30 */
}

.scheme-container .separator-OE4[number="2"]
{
    left: 206px;
    top: 592px;
}

.scheme-container .separator-OE4[number="3"]
{
    left: 173px;
    top: 780px;
}

.scheme-container .separator-OE4[number="4"]
{
    left: 218px;
    top: 836.5px;
}

.scheme-container .separator-LPGS2[number="1"]
{
    left: 266px;
    top: 593px;
}

.scheme-container .separator-LPGS2[number="2"]
{
    left: 337px;
    top: 593px;
}

.scheme-container .separator-LPGS2[number="3"]
{
    left: 306px;
    top: 780px;
}

.scheme-container .separator-LPGS2[number="4"]
{
    left: 352px;
    top: 838.5px;
}

.scheme-container .separator-OE2[number="1"]
{
    left: 390px;
    top: 593px;
}

.scheme-container .separator-OE2[number="2"]
{
    left: 461px;
    top: 593px;
}

.scheme-container .separator-OE2[number="3"]
{
    left: 430px;
    top: 780px;
}

.scheme-container .separator-OE2[number="4"]
{
    left: 474px;
    top: 837.5px;
}

.scheme-container .separator-CTVB[number="1"]
{
    left: 511.85px;
    top: 592px;
}

.scheme-container .separator-CTVB[number="2"]
{
    left: 583.5px;
    top: 592px;
}

.scheme-container .separator-GeneratorI[number="1"]
{
    left: 637px;
    top: 592px;
}

.scheme-container .separator-GeneratorI[number="2"]
{
    left: 708px;
    top: 592px;
}

.scheme-container .separator-GeneratorI[number="3"]
{
    left: 676px;
    top: 780px;
}

.scheme-container .separator-GeneratorI[number="4"]
{
    left: 721px;
    top: 836px;
}

.scheme-container .separator-CMIIB[number="4"]
{
    left: 749.78px;
    top: 620.23px;
}

.scheme-container .separator-CMIIB[number="3"]
{
    left: 794px;
    top: 664px;
}

.scheme-container .separator-CLI[number="1"]
{
    left: 865px;
    top: 592px;
}

.scheme-container .separator-CLI[number="2"]
{
    left: 1003px;
    top: 592px;
}

.scheme-container .separator-CMIIA[number="3"]
{
    left: 1082px;
    top: 664px;
}

.scheme-container .separator-CMIIA[number="4"]
{
    left: 1127.99px;
    top: 618.77px;
}

.scheme-container .separator-GeneratorII[number="1"]
{
    left: 1170px;
    top: 590px;
}

.scheme-container .separator-GeneratorII[number="2"]
{
    left: 1243.5px;
    top: 590px;
}

.scheme-container .separator-GeneratorII[number="3"]
{
    left: 1209.5px;
    top: 771px;
    
}

.scheme-container .separator-GeneratorII[number="4"]
{
    left: 1255px;
    top: 827.5px;
}

.scheme-container .separator-CTVA[number="1"]
{
    left: 1306.85px;
    top: 592px;
}

.scheme-container .separator-CTVA[number="2"]
{
    left: 1378.5px;
    top: 592px;
}

.scheme-container .separator-OE3[number="1"] 
{
    left: 1463px;
    top: 592px;
}

.scheme-container .separator-OE3[number="2"] 
{
    left: 1536px;
    top: 592px;
}

.scheme-container .separator-OE3[number="3"] 
{
    left: 1502px;
    top: 780px;
}

.scheme-container .separator-OE3[number="4"] 
{
    left: 1546px;
    top: 837.5px;
}

.scheme-container .separator-OE1[number="1"]
{
    left: 1657.4px;
    top: 592px;
}

.scheme-container .separator-OE1[number="2"]
{
    left: 1729.4px;
    top: 592px;
}

.scheme-container .separator-OE1[number="4"]
{
    left: 1739.4px;
    top: 836.5px;
}

.scheme-container .separator-OE1[number="3"]
{
    left: 1695px;
    top: 780px;
}

.scheme-container .generator 
{
    width: 45px;
    height: 45px;
    position: absolute;
    cursor: pointer;
}

.scheme-container .generator img
{
    width: 100%;
    stroke: #000;
}


.scheme-container .generator-GeneratorI
{
    left: 668px;
    top: 847px;
}

.scheme-container .generator-GeneratorII 
{
    left: 1203px;
    top: 852px;
}

.scheme-container .cell .circle
{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 350ms ease-in-out;
}

.scheme-container .cell.down-text .circle
{
    align-items: flex-end;
}

.scheme-container .cell .circle .text-circle 
{
    font-size: 14px;
}

.scheme-container .cell.inactive .circle
{
    border-color: #3AD766;
}

.scheme-container .cell.active .circle
{
    border-color: crimson;
}

.scheme-container .cell-CMIIB .circle:first-child
{
    left: 793.75px;
    top: 706px;
}

.scheme-container .cell-CMIIB .circle:nth-child(2)
{
    left: 783px;
    top: 721.5px;

}

.scheme-container .cell-CMIIB .circle:last-child 
{
    left: 803.02px;
    top: 721.46px;
}


.scheme-container .cell-CMIIA .circle:first-child
{
    left: 1082.31px;
    top: 705.45px;
}

.scheme-container .cell-CMIIA .circle:nth-child(2) 
{
    left: 1072.3px;
    top: 721.45px;
}

.scheme-container .cell-CMIIA .circle:last-child
{
    left: 1092.3px;
    top: 720.46px;
}

.scheme-container .cell-CMIA .circle:first-child
{
    left: 1072px;
    top: 301px;
}

.scheme-container .cell-CMIA .circle.circle:nth-child(2)
{
    left: 1092.02px;
    top: 301.34px;
}

.scheme-container .cell-CMIA .circle.circle:last-child 
{
    left: 1080.98px;
    top: 316.63px;
}

.scheme-container .cell-CMIB .circle:first-child
{
    left: 785px;
    top: 305px;
}

.scheme-container .cell-CMIB .circle:nth-child(2)
{
    left: 805.02px;
    top: 305.34px;
}

.scheme-container .cell-CMIB .circle:last-child 
{
    left: 793.98px;
    top: 320.63px;
}

.scheme-container .message-content:not(.active)

{
    display: none;
}

.scheme-container .message-content.active
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}


.scheme-container .message-content.active .message-container
{
    width: 20%;
    height: 20%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

}

.scheme-container .message-content.active .message-container p 
{
    font-size: 18px;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 10%;
}

.scheme-container .message-content.active .message-container .buttons 
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: auto;
    margin-bottom: 20px;
    gap: 1rem;
}

.scheme-container .message-content.active .message-container .buttons button 
{
    flex: 1;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #45A6FF;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    transition: all 350ms ease-in-out;
  
}

.scheme-container .message-content.active .message-container .buttons button:hover
{
    background: #45A6FF;
    color: #fff;
}

.scheme-container .message-content.active .message-container .buttons button.inactive 
{
    display: none;
}