.side-menu 
{
    position: fixed;
    top: 2.5mm;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 1000;
    padding-top: 20px;
    padding-inline: 24px;
    overflow-y: auto;
    align-items: flex-start;
    transition: all 350ms ease-in-out;
}

.side-menu:not(.edit-menu)
{
    left: 2.5mm;
}

.side-menu.edit-menu 
{
    right: 0mm;
    top: 0mm;
    /* left: initial; */
    bottom: 0mm;
    align-items: flex-end;
}


.side-menu.activated:not(.edit-menu)
{
    left: 0mm;
    top: 0mm;
    z-index: 1000;
}

.side-menu.activated 
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    height: 100%;
}

.side-menu .option 
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    position: relative;
    transition: all 350ms ease-in-out;
}


.side-menu .option .option-text p 
{
    color: #fff;
    font-weight: 700;
    white-space: nowrap;
    font-size: 0;
    transition: all 450ms ease-in-out;
}

.side-menu .option .option-text 
{
    opacity: 0;
    position: relative;
    /* left: 50px; */
    z-index: 10;
    background: rgba(0, 0, 0, .9);
    border-radius: 25px;
    /* top: 0;
    left: 0;
    bottom: 0; */
    /* display: none; */
    user-select: none;
    width: 0;
    transition: all 450ms ease-in-out;
}

.side-menu .option .option-text.relative-pos
{
    position: relative;
    left: 0;
}

.side-menu .option .menu-icon
{
    /* box-shadow: 0 0 5px #000 inset; */
    /* border: 3px solid #fff; */
}

/* .side-menu .option .menu-icon-line 
{
    width: 100%;
    height: 3px;
    background: #fff;
    position: absolute;
    transform: rotateZ(135deg) translateY(-5px);
} */

.side-menu .option.selected .menu-icon
{
    color: rgb(97, 152, 246);
    background: #fff;

}

/* .side-menu .option:hover .menu-icon
{
    color: rgb(97, 152, 246);
    background: #fff;

} */

.side-menu .option:last-child 
{
    margin-bottom: 40px;
}

.side-menu .option:hover .menu-icon
{
    box-shadow: 0 0 10px #000 inset;
    box-shadow: -2.5px -2.5px 7px #fff inset,
    -5px 0px 5px #000;
    /* border: 3px solid #fff; */
    padding: .65rem;
   
}

.side-menu .option:hover .menu-icon
{
    transform: scale(1.2);
}

.side-menu .option .option-text
{
    transition: all 350ms ease-in-out;
    transition-delay: 0s;
}

.side-menu .option:hover .option-text,
.side-menu .option:hover .option-text p 
{
    transition-delay: 0.6s;
}

.side-menu .option.active .option-text,
.side-menu .option:hover .option-text
{
    width: 100%;
    opacity: 1;
    cursor: pointer;
    padding: .5rem 1rem;
}

.side-menu .option.active .option-text p,
.side-menu .option:hover .option-text p
{
    font-size: 18px;

}

.side-menu .option.reverse-position
{
    flex-direction: row-reverse;
}

.side-menu .option.reverse-position .option-text
{
    left: initial;
    /* right: 50px; */
}

.side-menu .menu-icon 
{
    cursor: pointer;
    width: 28px;
    height: 28px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: .5rem;
    
    transition: all 350ms ease-in-out;

}

.side-menu:not(.activated) .option:first-child .menu-icon
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: .65rem;
    border-radius: 50%;
    color: #fff;
    width: 25px;
    height: 25px;
    
}

/* .side-menu.activated .option:first-child .menu-icon,
.side-menu.activated .option:nth-child(3) .menu-icon,
.side-menu.activated .option:nth-child(10) .menu-icon
{
    border: 2px dotted #fff;
    
} */

.side-menu:not(.activated) .menu-icon 
{
    color: #000;
}

.side-menu .menu-icon 
{
    color: #fff;
}

.edit-menu 
{
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 10000000001;
}