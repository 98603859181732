h2.process-text
{
    font-size: 7.5vw;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Inter", "Source Sans Pro", sans-serif;
}

h2.process-text:not(span)
{
    background: -webkit-linear-gradient(180deg, #000 5%, #63808a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


h2.process-text span 
{
    background: -webkit-linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 50%;
   

    
    animation: moveDots 1000ms infinite;
}

h2.process-text span:nth-child(2) 
{
    animation-delay: 150ms;
}

h2.process-text span:nth-child(3)
{
    animation-delay: 300ms;
} 


@keyframes moveDots {
    0% {
        transform: translateY(0px);
    } 25% {
        transform: translateY(-40px);
    } 50% {
        transform: translateY(0px);
    }
}